button {
  transition: var(--tokens__transition);
}

.button {
  &--secondary {
    color: var(--tokens__colors__dark-grey);
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.375rem 1rem;
    font-weight: 700;
    border: 1px solid rgba(0, 0, 0, 0);

    @include Hover-Supported() {
      &:hover {
        box-shadow: none;
        border: 1px solid var(--tokens__colors__dark-grey);
      }
    }

    &--active {
      color: white;
      background: var(--tokens__colors__dark-grey);

      &:hover {
        background: var(--tokens__colors__dark-grey__hover);
      }
    }
  }
}
