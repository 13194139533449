@supports (-webkit-appearance: none) {

  // make svgs on safari work properly with max width
  svg {
    width: 100%;
  }
}

// Only show outline when user is tabbing
body:not(.user-is-tabbing) *:focus {
  outline: none;
}

.esri-icon.esri-icon-plus {
  background: none !important;

  &:before {
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    background: url('/assets/img/icons/map/plus.svg');
    background-size: contain;
  }
}

.esri-icon.esri-icon-minus {
  background: none !important;
  height: auto;

  &:before {
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    background: url('/assets/img/icons/map/minus.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}