:root {
  @include MQ-Above(medium) {
    --filter-bar__gap: 2rem;
    --filter-bar__padding: 0.8rem 2%;
  }
  @include MQ-Above(large) {
    --filter-bar__flex-direction: row;
    --filter-bar__label__margin: 0 0.5rem 0 0;
  }
}

.filter-bar {
  background: var(--tokens__colors__medium-grey);

  ul {
    display: grid;
    grid-template-columns: var(--grid__columns--two);
    grid-gap: var(--filter-bar__gap, 1rem);
    max-width: 64rem;
    margin: auto;
    padding: var(--filter-bar__padding, 0.8rem 8%);

    @include MQ-Above(medium) {
      grid-template-columns: var(--grid__columns--three);
    }

    li {
      display: flex;
      align-items: center;
      flex-direction: var(--filter-bar__flex-direction, column);

      label {
        font-weight: 700;
        font-size: 10px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin: var(--filter-bar__label__margin, 0 0 0.3rem 0);
      }

      select {
        min-width: 200px;
        width: 100%;
      }
    }
  }

  &__message {
    text-align: center;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 0.65rem;
    font-weight: 700;
    letter-spacing: 1px;
    background: var(--tokens__colors__light-blue);

    button,
    p {
      display: inline-block;
    }

    button {
      text-decoration: underline;
      margin: 0 10px;
    }
  }
}
