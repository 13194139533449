:root {
  --postal-lookup__hover-color: var(--tokens__colors__dark-grey);

  @include MQ-Above(medium) {
    --postal-lookup__font-size: 1rem;
  }
}

.postal-lookup {
  position: relative;
  font-size: var(--postal-lookup__font-size, 0.9rem);

  .loader {
    width: 40px;
    height: 40px;
    margin: auto 10px auto 0;
  }

  &__input {
    display: flex;
    background: white;
    margin-top: 1.5rem;
    box-shadow: 2px 6px 8px rgba(88, 44, 131, 0.1);

    input {
      flex: 1;
      margin: 0;
    }
  }

  &__multi {
    position: absolute;
    z-index: 40;
    top: 100%;
    width: 100%;

    header {
      background: var(--tokens__colors__dark-grey);
      color: white;
      font-weight: 600;
      padding: 1rem;
      text-align: center;
      font-size: 0.7rem;
    }

    &__inner {
      background: white;
      max-height: 40vh;
      overflow-y: auto;
    }

    ul {
      li {
        border-bottom: 1px solid var(--tokens__colors__light-grey);
        font-size: 0.8rem;

        &:hover {
          background: var(--tokens__colors__light-grey);
        }

        button {
          text-align: left;
          width: 100%;
          padding: 1rem;
        }
      }
    }
  }
}
