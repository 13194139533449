:root {
  @include MQ-Above(medium) {
    --search__inner__padding: 4% 8%;
    --search__postal-lookup__padding: 10px;
  }
}

.search {
  background: var(--tokens__colors__dark-gold);
  color: var(--tokens__colors__dark-grey);
  margin-top: 2rem;

  &__inner {
    align-items: center;
    max-width: var(--tokens__max-width);
    margin: auto;
    position: relative;
    padding: var(--search__inner__padding, 8%);
    text-align: center;

    &__info {
      font-size: 1.3rem;
      line-height: 1.2;
    }
  }

  &__error {
    margin-top: 1rem;
    font-size: 1.1rem;
  }

  .postal-lookup {
    &__input {
      box-shadow: 0px 8px 15px rgba(59, 61, 63, 0.4);
      padding: var(--search__postal-lookup__padding, 5px);
      border-radius: var(--tokens__border-radius);

      input {
        @include MQ-Above(medium) {
          text-align: center;
        }

        // Hide centered placeholder
        // All following props should be separated
        &:focus::placeholder {
          color: transparent;
        }
        
        &:focus::-webkit-input-placeholder {
          color: transparent;
        }

        &:focus:-moz-placeholder{
          color: transparent;
        }

        &:focus::-moz-placeholder{
          color: transparent;
        }

        &:focus:-ms-input-placeholder {
          color: transparent;
        }
      }

      .primary-cta {
        width: 100px;

        @include MQ-Above(medium) {
          width: 120px;
        }
      }
    }
  }
}
