:root {
  @include MQ-Above(medium) {
    --site-footer__flex-direction: row;
    --site-footer__nav-margin: 0 1.25rem;
    --site-footer__padding: 0;
  }
}

.site-footer {
  background: white;
  position: relative;
  z-index: 15;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  flex-direction: var(--site-footer__flex-direction, column);
  align-items: center;
  margin: auto;
  border-top: 1px solid #e5e5e5;
  padding: var(--site-footer__padding, 1rem);
  gap: 10px;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    background: var(--tokens__colors__dark-gold);
    min-height: 72px;

    svg {
      width: 164px;

      @include MQ-Above(large) {
        width: 194px;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 1.25rem;
    margin: var(--site-footer__nav-margin, 1rem 0 0);

    li {
      padding: 0.5rem;
      line-height: 1.3;

      a,
      button {
        text-decoration: none;
        color: var(--tokens__colors__dark-grey);

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  &__legal {
    font-size: 0.75rem;
    padding: 0 1rem;
    text-align: center;
  }
}
