// Special styles
// Add any hacky stuff, vendor CSS, or other yucky crap here

.util__fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.util__image-fill {
  width: 100%;
  height: auto;
}

.util__blend-mode-multiply {
  mix-blend-mode: multiply;
}

.util__faded30 {
  opacity: 0.3;
}

// Accessibility utilities

.util__screen-reader-only {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute !important;
}

.util__supress-focus {
  outline: none !important;
}

// Responsive utilities

.util__desktop-only {
  @include MQ-Below(medium) {
    display: none !important;
  }
}

.util__mobile-only {
  @include MQ-Above(medium) {
    display: none !important;
  }
}

// Misc utilities

.util__truncate {
  white-space: nowrap;
  overflow: hidden; // Overflow-x tends to cause vertical scroll bars
  text-overflow: ellipsis;
  line-height: 1.5; // Helps prevent cutoff due to overflow rule
}
// NB: For truncation to work, the parent element must have some determinate width, so apply the following rule to it:
// min-width: 0; // Must be defined for truncation to work on child elements
// References:
// https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
// https://css-tricks.com/flexbox-truncated-text/

.util__text-centered {
  text-align: center;
}

.util__hidden {
  display: none;
}

.util__disabled {
  opacity: 0.3;
  pointer-events: none;
}
