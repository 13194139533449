:root {
  @include MQ-Above(medium) {
    --react-tabs__tab__font-size: 0.75rem;
  }
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-item__title {
    font-size: 0.625rem;
    text-transform: uppercase;
    color: var(--tokens__colors__dark-grey);
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  &__tab-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.5rem;
  }

  &__tab {
    position: relative;
    list-style: none;
    cursor: pointer;
    padding: 1.25rem;
    font-size: var(--react-tabs__tab__font-size, 0.55rem);
    font-weight: 700;
    border-bottom: 2px solid var(--tokens__colors__medium-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tokens__colors__dark-grey);
    transition: .12s ease-out;

    &::after {
      content: "";
      width: 100%;
      height: 5px;
      background: var(--tokens__colors__blue);
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0;
      transition: transform 0.15s ease-in;
      transform: scaleX(0);
      transform-origin: center center;
    }

    &:hover {
      background-color: var(--tokens__colors__medium-grey);
    }

    &--selected {
      &::after {
        transform: scaleX(1);
      }
    }
  }
}
