/* SCSS variables for things for which that makes sense */
$Colors__DarkGold: #FAD141;
$Colors__DarkGold__Hover: #F7DB74;
$Colors__LightGold: #FDE57F;
$Colors__Blue: #0090C2;
$Colors__LightBlue: #8BBDD9;
$Colors__LightGrey: #F1F3F4;
$Colors__MediumGrey: #CDCCCB;
$Colors__DarkGrey: #3B3D3F;
$Colors__DarkGrey__Hover: #767576;

$Layer__Width--Narrow: 41.25rem;

:root {
  /* Primary Colors */
  --tokens__colors__dark-gold: #{$Colors__DarkGold};
  --tokens__colors__dark-gold__hover: #{$Colors__DarkGold__Hover};
  --tokens__colors__light-gold: #{$Colors__LightGold};
  --tokens__colors__blue: #{$Colors__Blue};
  --tokens__colors__light-blue: #{$Colors__LightBlue};

  /* Utility Colors */
  --tokens__colors__light-grey: #{$Colors__LightGrey};
  --tokens__colors__medium-grey: #{$Colors__MediumGrey};
  --tokens__colors__dark-grey: #{$Colors__DarkGrey};
  --tokens__colors__dark-grey__hover: #{$Colors__DarkGrey__Hover};
  --tokens__colors__white--faded-50: fade(white, 50%);
  --tokens__colors__black--faded-50: fade(black, 50%);

  /* Font Families */
  --tokens__font-family__base: "Source Sans Pro", sans-serif;

  /* Transition */
  --tokens__transition: all 0.15s;

  /* Sizing */
  --tokens__max-width: 1350px;
  --tokens__header__max-width: 55rem;

  /* Shadows */
  --tokens__box-shadow: 0 1px 4px rgba(88, 44, 131, 0.36);

  /* Border Radius */
  --tokens__border-radius: 8px;
}
