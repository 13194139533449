:root {
  --segment-details__slide--height: auto;
  
  @include MQ-Above(medium) {
    --segment-details__slide--width: 80vw;
    --segment-details__controls__button__padding: 0 20px;
    --segment-details__button-arrow__scale: scale(1);
    --segment-details__grid-gap: 2rem;
    --segment-details-font-size: 0.9rem;
  }
}

.segment-details {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;

  p {
    font-size: var(--segment-details-font-size, 0.8rem);
  }

  h2 {
    color: var(--tokens__colors__dark-grey);
    display: inline-flex;
  }

  &__title {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto auto 0.375rem auto;

    @include MQ-Above(medium) {
      flex-direction: row;
    }
  }

  &__number {
    @extend .title--subtitle;

    display: inline-flex;
    font-size: var(--title__font-size);
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    padding: 0.3125rem;
    background: var(--tokens__colors__dark-grey);
    margin-bottom: 0.375rem;

    @include MQ-Above(medium) {
      margin-bottom: 0;
      margin-right: 0.625rem;
    }

    &--star {
      width: 32px;
      height: 32px;
      border-radius: 100%;

      svg {
        width: 20px;
        height: 20px;
      }

      @include MQ-Above(medium) {
        width: 38px;
        height: 38px;
      }
    }
  }

  &__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.375rem;

    @include MQ-Above(medium) {
      margin-bottom: 0;
      margin-right: 0.625rem;
    }

    &__label {
      display: inline-flex;
      text-align: right;
      margin-right: 0.375rem;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    &__icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.3125rem;
      background: var(--tokens__colors__dark-grey);
      width: 32px;
      height: 32px;
      border-radius: 100%;

      svg {
        width: 20px;
        height: 20px;
      }

      @include MQ-Above(medium) {
        width: 38px;
        height: 38px;
      }
    }
  }

  &__represents {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--tokens__colors__dark-grey);
    margin-bottom: 0.5rem;
  }

  &__section {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  &__slide {
    flex: 0 0 var(--tokens__max-width);
    max-width: var(--segment-details__slide--width, 95vw);
    padding: 2rem;
    text-align: center;
    opacity: 0.3;
    height: var(--segment-details__slide--height, 0);
    position: relative;
    z-index: 1;

    &[aria-hidden="true"] {
      display: none;
    }

    > header {
      margin-bottom: 1.5rem;

      p {
        em {
          font-style: normal;
          font-weight: 700;
        }
      }
    }

    &--active {
      opacity: 1;
      animation: animation__fadeUp 2s 1 cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &__who {
      text-align: left;
      display: grid;
      grid-template-columns: var(--grid__columns--two);
      grid-gap: 2rem;
      font-size: var(--segment-details-font-size, 0.8rem);

      &__text {
        strong {
          font-weight: 600;
        }
      }

      button {
        color: var(--tokens__colors__dark-grey);
        text-decoration: underline;
      }

      ul {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: var(--grid__columns--three);
      }

      p {
        word-break: break-word;
      }
    }

    &__think {
      ul {
        display: grid;
        grid-gap: 2rem;
      }
    }

    &__live {
      ul {
        display: grid;
        grid-template-columns: var(--grid__columns--three);
        grid-gap: var(--segment-details__grid-gap, 2rem 1rem);

        img {
          margin-bottom: 0.5rem;
        }

        p {
          line-height: 1.2;
          font-size: var(--segment-details-font-size, 0.8rem);
          word-break: break-word;
        }
      }
    }
  }

  &__short-description {
    font-style: italic;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 1px;
      background: black;
      margin: 10px auto;
    }
  }

  &__controls {
    position: absolute;
    padding-top: 2.5rem;
    top: 0;
    z-index: 5;
    height: 100%;

    .button-arrow {
      transform: var(--segment-details__button-arrow__scale, scale(0.5));
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &--left,
    &--right {
      @include Hover-Supported() {
        &:hover {
          --button-arrow__background: var(--tokens__colors__dark-grey);
          --button-arrow__color: white;
        }
      }
    }

    @include MQ-Above(medium) {
      &--left {
        background: linear-gradient(-269.33deg, #fff 2.55%, rgba(255, 255, 255, 0.2) 52.05%, rgba(255, 255, 255, 0) 98.56%);
      }

      &--right {
        background: linear-gradient(269.33deg, #fff 2.55%, rgba(255, 255, 255, 0.2) 52.05%, rgba(255, 255, 255, 0) 98.56%);
      }
    }

    &__button {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding: var(--segment-details__controls__button__padding, 0);
    }
  }
}

@keyframes animation__fadeUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
