:root {
  @include MQ-Above(medium) {
    --filter-stats__stat-display: inline-block;
    --filter-stats__columns: repeat(4, 1fr);
    --filter-stats__button-columns: auto;
  }
}

.filter-stats {
  display: flex;
  justify-content: center;
  padding: 1rem 8%;
  background: white;

  ul {
    display: grid;
    grid-template-columns: var(--filter-stats__columns, repeat(3, 1fr));
    grid-gap: 1rem;
    align-items: center;
    width: 100%;
    max-width: 900px;

    li {
      text-align: center;
      font-size: 0.9rem;

      &:nth-child(4) {
        grid-column: var(--filter-stats__button-columns, 1 / 4);
      }

      b {
        margin-right: 5px;
        display: var(--filter-stats__stat-display, block);

        .loader {
          height: 15px;
          width: 15px;
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  &__icon {
    position: relative;
    width: 8px;
    height: 2px;
    display: inline-flex;
    margin: 0 10px 0 0;
    background: currentColor;

    &::after,
    &::before {
      content: "";
      position: absolute;
      display: block;
      background: currentColor;
      height: 2px;
    }

    &::before {
      width: 12px;
      top: -4px;
      left: -2px;
    }

    &::after {
      width: 4px;
      top: 4px;
      left: 2px;
    }
  }
}
