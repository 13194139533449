select {
  appearance: none;
  box-shadow: none;
  border: 0 !important;
  color: #333;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0;
  font-size: 16px;
  position: relative;
  width: 100%;
  background-image: url("/assets/img/icons/carat.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.75rem top 50%, 0 0;
  background-size: 0.8rem auto, 100%;
  background-color: white;
}

select::-ms-expand {
  display: none;
}

.select-group {
  position: relative;
  flex: 1;
  width: 100%;
  border-radius: var(--tokens__border-radius);
  overflow: hidden;

  select {
    position: relative;
  }

  &__clear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    background: linear-gradient(270deg, #fff 68.03%, rgba(255, 255, 255, 0) 100%);
    padding: 0 10px 0 20px;
    display: none;

    &:hover {
      &::after {
        transform: scale(1.1);
      }
    }

    &::after {
      content: "";
      display: block;
      z-index: 5;
      width: 22px;
      height: 24px;
      background-image: url("/assets/img/icons/close.svg");
      transition: transform 0.1s ease-in;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--active {
      display: block;
    }
  }
}