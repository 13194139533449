:root {
  @include MQ-Above(medium) {
    --gdpr-banner__columns: 3fr 1fr;
  }
}

.gdpr-banner {
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: var(--gdpr-banner__columns, auto);
  grid-gap: 1rem;
  position: fixed;
  bottom: 0;
  z-index: 35;
  background: var(--tokens__colors__dark-grey);
  color: white;
  font-size: 0.9rem;
  width: 100%;

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    &__decline {
      text-decoration: underline;
    }

    button {
      text-align: center;
      color: white;
    }
  }

  .primary-cta {
    background: var(--tokens__colors__dark-gold);
    color: var(--tokens__colors__dark-grey);

    &:hover {
      background: var(--tokens__colors__dark-gold__hover);
      color: var(--tokens__colors__dark-grey);
    }
  }
}
