:root {
  --button-arrow__background: var(--tokens__colors__light-grey);
  --button-arrow__color: var(--tokens__colors__dark-grey);

  @include MQ-Above(medium) {
    --button-arrow__scale: scale(1);
  }
}

.button-arrow {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 55px;
  height: 55px;
  border: 2px solid var(--tokens__colors__dark-grey);
  border-radius: 100px;
  font-size: 0;
  transform: var(--button-arrow__scale, scale(0.8));
  transform-origin: top;
  background: var(--button-arrow__background);
  box-shadow: var(--tokens__box-shadow);
  transition: var(--tokens__transition);

  @include Hover-Supported() {
    &:hover {
      --button-arrow__background: var(--tokens__colors__dark-grey);
      --button-arrow__color: white;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
  }

  &--left::after {
    border-bottom: 3px solid var(--button-arrow__color);
    border-left: 3px solid var(--button-arrow__color);
    transform: rotate(45deg);
    left: 19px;
    top: 15px;
  }

  &--right::after {
    border-bottom: 3px solid var(--button-arrow__color);
    border-right: 3px solid var(--button-arrow__color);
    transform: rotate(-45deg);
    left: 12px;
    top: 15px;
  }
}
