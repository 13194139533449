.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 30;
  padding: 0 1rem;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    background: white;
    box-shadow: 0 8px 22px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translateY(-50%) translateX(-50%);
    width: 40rem;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;

    p {
      margin: 0.5rem 0 1rem;
    }

    &__close {
      width: 22px;
      height: 22px;
      border: 2px solid transparent;
      border-radius: 40px;
      position: absolute;
      top: 10px;
      right: 10px;

      &::after,
      &::before {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 16px;
        height: 2px;
        background: currentColor;
        transform: rotate(45deg);
        border-radius: 5px;
        top: 8px;
        left: 1px;
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    &__error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      padding: 1rem;
      display: block;
      width: 100%;
    }

    &--interstitial {
      background: var(--tokens__colors__dark-grey);
      color: white;

      .modal__inner__close {
        color: white;
      }

      .primary-cta {
        --primary-cta__background-color: var(--tokens__colors__dark-gold);

        color: var(--tokens__colors__dark-grey);
      }
    }

    &--limit {
      background: var(--tokens__colors__blue);
    }

    &--login {
      box-shadow: none;
      
      .field {
        width: 100%;
        max-width: 600px;
        text-align: left;
      }

      p {
        font-size: 0.85rem;
        margin: 0 0 1rem;
      }

      label {
        display: block;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }

      input {
        border: 1px solid var(--tokens__colors__medium-grey);
        border-radius: var(--tokens__border-radius);
        height: 36px;
        margin-bottom: 1rem;
        
        &:hover,
        &:focus {
          border-color: var(--tokens__colors__dark-grey);
        }
      }

      button {
        min-width: 176px;
        margin: 0.5rem 0 1rem;
      }
    }

    &--details {
      align-items: stretch;

      p,
      ul,
      ol {
        text-align: left;
        line-height: 1.6;
      }

      ul,
      ol {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
      }

      ol {
        & > li {
          display: list-item;
          list-style-type: auto;

          ul {
            margin-top: 0.5rem;
            padding-left: 2rem;
          }
        }
      }

      ul {
        margin-top: 0.5rem;
        padding-left: 2rem;

        & > li {
          display: list-item;
          list-style-type: disc;
        }
      }
    }

    &--contact {
      width: 30rem;

      p {
        line-height: 1.5rem;
      }

      @include MQ-Above(medium) {
        padding: 2.5rem;
      }
      
      .buttons-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        padding-top: 0.5rem;

        .primary-cta {
          white-space: normal;
        }
      }
    }
  }
}
