:root {
  @include MQ-Above(medium) {
    --hero__min-height: 60vh;
    --hero__inner__padding: 4% 8%;
    --hero__intro__text-align: left;
    --hero__columns: 0.7fr 1fr;
    --hero__map__height: 70vh;
    --hero__map__point__size: 200px;
  }
}

.hero {
  min-height: var(--hero__min-height, auto);
  display: flex;
  align-items: center;

  &__inner {
    display: grid;
    grid-template-columns: var(--hero__columns, 1fr);
    align-items: center;
    max-width: 125rem;
    margin: auto;
    position: relative;
  }

  &__intro {
    text-align: var(--hero__intro__text-align, center);
    padding: var(--hero__inner__padding, 8% 8% 0 8%);
    color: var(--tokens__colors__dark-grey);

    &__error {
      color: #d64623;
      margin-top: 0.85rem;
      font-size: 0.85rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.15s ease-in;

      &--active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__map {
    position: relative;

    ul {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;

      li {
        left: 50%;
        top: 50%;
      }

      @include MQ-Above(small) {
        li {
          &:nth-child(1n + 1) {
            top: 36%;
            left: 25%;
          }

          &:nth-child(2n + 1) {
            top: 73%;
            left: 44%;
          }

          &:nth-child(3n + 1) {
            top: 62%;
            left: 74%;
          }
        }
      }
    }

    &__point {
      background: var(--tokens__colors__dark-grey);
      position: absolute;
      width: 0;
      height: 0;
      transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      opacity: 0;
      will-change: transform;
      z-index: 1;

      a {
        display: block;
      }

      &[aria-hidden="true"] {
        display: none;
      }

      &__info {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background: linear-gradient(359.51deg, var(--tokens__colors__dark-grey) 7%, rgba(59, 61, 63, 0) 46.1%);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        color: white;
        padding: 1rem;
        box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0), 0 0.625rem 0.625rem rgba(59, 61, 63, 0.26);
        transition: all 0.3s ease-in;

        &:hover {
          box-shadow: inset 0 0 0 3px var(--tokens__colors__dark-grey), 0 1.5rem 1.5rem rgba(59, 61, 63, 0.26);
        }

        &__title {
          position: relative;
          z-index: 5;
          text-align: center;
          display: block;
          padding: 0 10px;
          min-width: var(--hero__map__point__size, 180px);
          font-weight: 600;
        }

        img {
          z-index: 1;
        }
      }

      &--active {
        margin: calc(-1 * var(--hero__map__point__size, 180px) / 2);
        width: var(--hero__map__point__size, 180px);
        height: var(--hero__map__point__size, 180px);
        opacity: 1;
        z-index: 10;
      }
    }
  }

  &__scroll-to {
    font-size: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: auto;
    color: var(--tokens__colors__dark-grey);
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      left: 4px;
      top: 2px;
    }
  }

  .postal-lookup {
    &__input {
      border: none;
      background: none;
      border-radius: var(--tokens__border-radius);
      box-shadow: 2px 6px 8px rgba(59, 61, 63, 0.1);

      &::after {
        display: none;
      }

      input {
        border: 1px solid var(--tokens__colors__medium-grey);
        border-right-width: 0;
        border-top-left-radius: var(--tokens__border-radius);
        border-bottom-left-radius: var(--tokens__border-radius);
  
        &:hover,
        &:focus {
          border-top-color: var(--tokens__colors__dark-grey);
          border-left-color: var(--tokens__colors__dark-grey);
          border-bottom-color: var(--tokens__colors__dark-grey);
        }
      }
  
      .primary-cta {
        background: var(--tokens__colors__dark-gold);
        color: var(--tokens__colors__dark-grey);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 120px;

        @include MQ-Above(large) {
          width: 150px;
        }

        &:hover {
          background: var(--tokens__colors__dark-gold__hover);
          color: var(--tokens__colors__dark-grey);
        }
      }
    }

    &__multi {
      display: none;
    }
  }
}
