:root {
  --title__font-family: var(--tokens__font-family__base);
  --title__font-size: 1.8rem;
  --title__font-weight: 700;
  --title__line-height: 1.4;
  --title__color: "currentColor";
}

.title {
  font-family: var(--title__font-family);
  font-size: var(--title__font-size);
  font-weight: var(--title__font-weight);
  line-height: var(--title__line-height);
  color: var(--title__color);
}

/*
 * Extensions
 */

.title--main {
  --title__font-weight: 700;
  --title__line-height: 1.1;
  --title__font-size: 1.5rem;

  @include MQ-Between(medium, large) {
    --title__font-size: 2rem;
  }

  @include MQ-Above(large) {
    --title__font-size: 2.4rem;
  }
}

.title--secondary {
  --title__font-size: 1.4rem;
  --title__line-height: 1.25;
  --title__font-weight: 600;

  @include MQ-Above(medium) {
    --title__font-size: 1.7rem;
  }
}

.title--secondary--font-size-large {
  --title__line-height: 1.1;
  --title__font-size: 1.1rem;

  @include MQ-Between(medium, large) {
    --title__font-size: 1.4rem;
  }

  @include MQ-Above(large) {
    --title__font-size: 1.8rem;
  }
}

.title--secondary--font-size-small {
  --title__font-size: 1.2rem;

  @include MQ-Above(medium) {
    --title__font-size: 1.4rem;
  }
}

.title--subtitle {
  --title__font-size: 1rem;
  --title__line-height: 1.25;
  --title__font-weight: 400;

  @include MQ-Above(medium) {
    --title__font-size: 1.3rem;
  }
}

.title--subtitle--font-size-large {
  --title__font-size: 0.95rem;

  @include MQ-Between(medium, large) {
    --title__font-size: 1.15rem;
  }

  @include MQ-Above(large) {
    --title__font-size: 1.3rem;
  }
}

/*
 * Size Adjustments
 */

.title--font-size-medium {
  --title__font-size: 1.4rem;
}

.title--font-size-small {
  --title__font-size: 1.1rem;
}

.title--font-size-smaller {
  --title__font-size: 0.8rem;
}

/*
 * Weight Adjustments
 */

.title--font-weight-light {
  --title__font-weight: 300;
}

.title--font-weight-normal {
  --title__font-weight: 400;
}

.title--font-weight-semi-bold {
  --title__font-weight: 600;
}

/*
 * Spacing
 */

.title + .title {
  margin-top: 0.5rem;
}
