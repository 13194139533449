:root {
  @include MQ-Above(medium) {
    --map__container__height: 21rem;
  }
}

.map {
  position: relative;

  &__loader {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 1;

    .loader {
      width: 50px;
      height: 50px;
    }
  }

  &__container {
    position: relative;
    height: var(--map__container__height, 10rem);
    background: var(--tokens__colors__light-blue);

    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__instructions {
    @extend .util__mobile-only;

    text-align: center;
    padding: 1rem 1rem 0 1rem;
    text-transform: uppercase;
    color: var(--tokens__colors__dark-grey);
    font-size: 10px;
    letter-spacing: 1px;
  }
}