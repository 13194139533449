:root {
  --primary-cta__background-color: var(--tokens__colors__dark-grey);

  @include MQ-Above(medium) {
    --primary-cta__padding: 1rem 1.5rem;
  }
}

.primary-cta {
  display: inline-block;
  text-decoration: none; // Override default anchor property
  white-space: nowrap;
  cursor: pointer;
  padding: var(--primary-cta__padding, 1rem);
  background-color: var(--primary-cta__background-color);
  transition: all 0.15s ease-in;
  color: white;
  font-weight: 600;
  position: relative;
  border-radius: var(--tokens__border-radius);
  text-align: center;

  @include Hover-Supported() {
    &:hover {
      color: rgba(255, 255, 255, 0.9);
      background: var(--tokens__colors__dark-grey__hover);
      opacity: 1;
    }
  }
}
