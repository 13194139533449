.site-header {
  background: white;
  position: relative;
  z-index: 15;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin: auto;
  height: 60px;

  @include MQ-Above(large) {
    height: 72px;
  }

  @include MQ-Below(large) {
    --primary-cta__padding: 0.75rem 1.25rem;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    background: var(--tokens__colors__dark-gold);

    svg {
      width: 164px;

      @include MQ-Above(large) {
        width: 194px;
      }
    }
  }
}
