:root {
  @include MQ-Above(medium) {
    --site-nav__margin-right: 0.625rem;
  }
}

.site-nav {
  display: flex;
  min-height: 100%;
  margin-right: var(--site-nav__margin-right, 0);

  li {
    margin-left: 2rem;
    font-weight: 400;
    height: 100%;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

    span {
      cursor: pointer;
    }
  }

  &__menu-button {
    border-left: 1px solid #e5e5e5;
    height: 100%;
    padding: 0 25px;

    &__icon {
      &--active {
        color: var(--tokens__colors__dark-grey);
      }
    }

    &__icon,
    &__icon::before,
    &__icon::after {
      position: relative;
      display: block;
      width: 20px;
      height: 2px;
      background: currentColor;
    }

    &__icon::before,
    &__icon::after {
      content: "";
      position: absolute;
      top: -6px;
    }

    &__icon::after {
      top: 6px;
    }
  }
}
