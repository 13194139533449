.esri {
  &-view {
    display: flex;
    margin: 0;
    padding: 0;

    &-root {
      position: relative;
      flex: 1 1 100%;
      border: none;
      padding: 0;
      margin: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
    }

    &-surface {
      position: absolute;
      border: none;
      padding: 0;
      margin: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      -webkit-user-select: none;
      user-select: none;
      direction: ltr;
      cursor: grab;

      &[data-interacting="true"] {
        cursor: move;
        cursor: grabbing;
        cursor: -webkit-grabbing;
      }
    }
  }

  &-overlay-surface {
    position: absolute;
    border: none;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
  }

  &-ui {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    pointer-events: none;
    overflow: hidden;
    font-size: 14px;
    z-index: 0;

    &-inner-container {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    &-corner {
      max-width: 100%;
      position: absolute;
      display: flex;

      .esri-component {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        margin-bottom: 10px;
        pointer-events: auto;
      }
    }

    &-top-left {
      top: 0;
      right: 0;
      align-items: flex-start;
      flex-flow: column;
    }
  }

  &-zoom {
    display: flex;
    flex-flow: column nowrap;
  }

  &-widget {
    box-sizing: border-box;
    color: #323232;
    font-size: 14px;
    font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.3em;
    background-color: #fff;

    &--button {
      font-size: 14px;
      background-color: #fff;
      color: #6e6e6e;
      width: 32px;
      height: 32px;
      padding: 7px;
      margin: 0;
      overflow: hidden;
      cursor: pointer;
      text-align: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      transition: background-color 125ms ease-in-out;
      box-shadow: none;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  &-interactive {
    cursor: pointer;
  }

  &-icon {
    font-size: 16px;
    display: inline-block;
    animation: none;

    &-plus {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 11V20H11V11L20 11V10L11 10V0H10V10L0 10V11L10 11Z' fill='black'/%3E%3C/svg%3E%0A");
      background-size: cover;
      width: 100%;
      height: 100%;
    }

    &-minus {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='1' viewBox='0 0 20 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='20' y1='0.5' x2='-4.37114e-08' y2='0.499998' stroke='black'/%3E%3C/svg%3E%0A");
      background-size: contain;
      width: 100%;
      height: 1px;
    }

    &-font-fallback-text {
      clip: rect(0 0 0 0);
      overflow: hidden;
      position: absolute;
      height: 1px;
      width: 1px;
    }
  }

  &-disabled {
    .esri-icon {
      opacity: 0.4;
    }
  }

  &-attribution {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    color: white;
    display: flex;
    padding-bottom: 5px;

    &__sources {
      display: none;
    }

    &__powered-by {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      padding: 0 5px;
      text-align: right;
      white-space: nowrap;
      align-self: flex-end;
    }
  }
}
