:root {
  @include MQ-Above(medium) {
    --site-mobile-nav__visibility: hidden;
  }
}

.site-mobile-nav {
  background: rgba(255, 255, 255, 0.9);
  z-index: 30;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  visibility: var(--site-mobile-nav__visibility, visible);
  transition: opacity 0.3s ease-in;

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
  }

  ul {
    box-shadow: 0 8px 8px rgba(62, 27, 96, 0.19);

    li {
      width: 100%;
      text-align: center;
      border-top: 1px solid #e5e5e5;
      background: white;

      a,
      span {
        padding: 1.25rem;
        width: 100%;
        height: 100%;
        text-decoration: none;
        display: block;
        border-radius: 0;
      }
    }
  }
}
